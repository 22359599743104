import React from 'react';
import './Home.css'; // You can create Home-specific styles in Home.css if needed.

const Home = () => {
  return (
    <div>
      <header className="header">
        <h1>Welcome to Neural Trading Project</h1>
        <p>Revolutionizing cryptocurrency trading using cutting-edge machine learning and sentiment analysis.</p>
        <button className="get-started-btn">Get Started</button>
      </header>
      <section className="features">
        <h2>Key Features</h2>
        <p>Real-time cryptocurrency market analysis</p>
        <p>Advanced sentiment analysis and machine learning tools</p>
        <p>Open for developers, researchers, and investors</p>
      </section>
      <section className="collaborate">
        <h2>Collaborate with Us</h2>
        <p>
          Whether you are a developer, researcher, or an investor, Neural Trading Project welcomes your involvement in shaping the future
          of decentralized finance.
        </p>
      </section>
      {/* Place for future components like CryptoTable or graphs */}
    </div>
  );
};

export default Home;
