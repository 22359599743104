import React from 'react';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import './AboutProject.css'; // Import the CSS file

const AboutProject = () => {
  return (
    <div className="about-container">
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>About the Neural Trading Project</h1>
        <p>
          The Neural Trading Project is a pioneering non-profit initiative dedicated to the exploration and understanding of cryptocurrency markets, decentralized finance (DeFi), and other financial sectors. 
          By utilizing advanced machine learning algorithms, our goal is to analyze large and diverse datasets, including sentiment analysis, price action, trading volumes, order book data, DeFi metrics, and liquidity pools. 
          We aim to create a model that not only understands current market conditions but also adapts to its ever-changing nature, thereby delivering actionable insights to traders and investors across various financial markets.
        </p>
      </div>

      {/* Models Section */}
      <div className="models-section">
        <h2>Scientific and Mathematical Models</h2>
        <p>
          Our project integrates state-of-the-art algorithms and models to tackle the complex dynamics of financial markets. Below, we break down the key machine learning and deep learning methods applied in our system, explaining the theory, advantages, and how each is utilized for time series forecasting and trading.
        </p>

        {/* Model Card 1 */}
        <div className="model-card">
          <h3>1. GPTTrader: Large Language Models (LLM) for Sentiment Analysis</h3>
          <p>
            One of the cutting-edge components of our system is GPTTrader, a powerful tool based on OpenAI's GPT model. GPTTrader uses the capabilities of large language models (LLMs) to process and analyze textual data from various news sources, social media, and other relevant platforms to understand market sentiment. 
            Market sentiment plays a critical role in influencing price movements, and GPTTrader helps quantify this sentiment by classifying it as positive, negative, or neutral, which is then factored into trading decisions.
          </p>
          <BlockMath math={`S = \\frac{\\sum_{i=1}^{N} w_i \\cdot \\text{sentiment}(h_i)}{N}`} />
          <p>
            Where:
            <ul>
              <li><strong>S</strong>: The overall sentiment score</li>
              <li><strong>w_i</strong>: Weighting factor for sentiment</li>
              <li><strong>h_i</strong>: Historical sentiment data at time <strong>i</strong></li>
              <li><strong>N</strong>: Number of data points considered</li>
            </ul>
          </p>
          <h4>Why LLMs for Sentiment Analysis in Trading?</h4>
          <p>
            Traditional sentiment analysis tools often struggle with nuanced language, which is crucial in financial markets where subtle differences in tone can lead to significant market movements. 
            LLMs like GPT excel at understanding these nuances, offering superior performance in capturing market sentiment. GPTTrader integrates this information with technical and quantitative data to generate more informed trading decisions.
          </p>
          <p>
            The sentiment analysis performed by GPTTrader is then incorporated into our broader trading strategy, where the model advises trading actions based on sentiment trends in conjunction with technical indicators and time series data.
          </p>
        </div>

        {/* Model Card 2 */}
        <div className="model-card">
          <h3>2. Gradient Boosting for Time Series Forecasting</h3>
          <p>
            Gradient Boosting is one of the cornerstone algorithms we utilize. It’s an ensemble technique where multiple weak learners (typically decision trees) are combined to form a strong predictive model. 
            For time series forecasting, this method is incredibly powerful because it can model complex, non-linear relationships between various market variables, such as price, volume, and other technical indicators.
          </p>
          <BlockMath math={`F_m(x) = F_{m-1}(x) + \\eta \\cdot h_m(x)`} />
          <p>
            Where:
            <ul>
              <li><strong>F_m(x)</strong>: The model at iteration <strong>m</strong></li>
              <li><strong>h_m(x)</strong>: The new model added at iteration <strong>m</strong> to correct the previous errors</li>
              <li><strong>\\eta</strong>: The learning rate controlling how much new models correct the errors of the prior iterations</li>
            </ul>
          </p>
          <p>
            The Gradient Boosting algorithm is trained sequentially, where each new model attempts to improve upon the errors (residuals) made by the previous models. 
            For financial time series, where the relationship between variables is often non-linear and volatile, this iterative approach is extremely useful for uncovering hidden patterns in price movements.
          </p>
          <h4>Why Gradient Boosting for Financial Markets?</h4>
          <p>
            Financial data, especially in cryptocurrencies, is noisy and contains significant volatility. Gradient Boosting is ideal because of its ability to reduce overfitting, as it introduces weak models gradually, balancing between variance and bias. 
            By adding new models to reduce error, it avoids becoming overly sensitive to the noise in the data, which is a common problem in highly fluctuating markets.
          </p>
        </div>

        {/* Model Card 3 */}
        <div className="model-card">
          <h3>3. Recurrent Neural Networks (RNNs) and Long Short-Term Memory (LSTM) Networks</h3>
          <p>
            Time series data requires algorithms that can understand sequences and maintain memory across time steps. <strong>Recurrent Neural Networks (RNNs)</strong> were specifically designed to address this issue by maintaining a hidden state that carries information from one time step to the next.
            However, standard RNNs face a challenge known as the <strong>vanishing gradient problem</strong>, which makes it difficult for them to learn long-term dependencies. To overcome this, we use a more advanced variant of RNNs called <strong>Long Short-Term Memory (LSTM)</strong> networks.
          </p>
          <BlockMath math={`h_t = \\sigma(W_h x_t + U_h h_{t-1} + b_h)`} />
          <p>
            Where:
            <ul>
              <li><strong>h_t</strong>: The hidden state at time <strong>t</strong>, capturing the context of the previous states</li>
              <li><strong>x_t</strong>: The input at time <strong>t</strong></li>
              <li><strong>W_h</strong>, <strong>U_h</strong>: Weight matrices for input and hidden state</li>
              <li><strong>b_h</strong>: Bias term</li>
            </ul>
          </p>
          <h4>The LSTM Architecture</h4>
          <p>
            LSTMs are specifically designed to retain information over long sequences. The key component of an LSTM is its memory cell, which is regulated by three gates: the input gate, the forget gate, and the output gate. These gates control how much of the information is updated, forgotten, or passed to the next time step, making LSTMs well-suited for financial markets, where past events can significantly influence future price movements.
          </p>
          <BlockMath math={`c_t = f_t \\cdot c_{t-1} + i_t \\cdot \\tilde{c}_t`} />
          <p>
            Where:
            <ul>
              <li><strong>c_t</strong>: The cell state at time <strong>t</strong></li>
              <li><strong>f_t</strong>: Forget gate determining how much past information to retain</li>
              <li><strong>i_t</strong>: Input gate controlling how much new information to add</li>
              <li><strong>\\tildec_t</strong>: New candidate values for the cell state</li>
            </ul>
          </p>
          <h4>Why LSTMs for Financial Time Series?</h4>
          <p>
            LSTMs are highly effective in financial forecasting because they can learn both short-term and long-term dependencies. In trading, past market behavior, economic events, and external shocks can all have lingering effects on future price action. 
            By using LSTMs, we can incorporate long-term information into our models, improving their predictive power compared to traditional machine learning algorithms that only consider short-term patterns.
          </p>
        </div>

        {/* Model Card 4 */}
        <div className="model-card">
          <h3>4. XGBoost for Structured Financial Data</h3>
          <p>
            Another essential algorithm we employ is <strong>XGBoost</strong>, an efficient implementation of gradient-boosted decision trees that excels at handling structured data, like the high-dimensional feature sets we use for predicting financial market trends.
          </p>
          <BlockMath math={`y_i = \\sum_{k=1}^{K} f_k(x_i)`} />
          <p>
            Where:
            <ul>
              <li><strong>y_i</strong>: The predicted output for instance <strong>i</strong></li>
              <li><strong>f_k</strong>: The <strong>k</strong>-th tree in the ensemble</li>
              <li><strong>K</strong>: Total number of trees in the model</li>
            </ul>
          </p>
          <p>
            XGBoost is a versatile algorithm that can handle missing data, a common issue in real-world financial datasets. It also includes a built-in regularization mechanism to prevent overfitting, which is crucial when dealing with noisy and highly volatile market data.
          </p>
          <h4>Why XGBoost for Financial Markets?</h4>
          <p>
            XGBoost is particularly adept at handling large-scale, structured datasets where relationships between features are complex and non-linear. By applying this model to structured financial data (such as market indicators, macroeconomic data, etc.), we can generate highly accurate predictions that help guide our trading strategies.
          </p>
        </div>

        {/* Model Card 5 */}
        <div className="model-card">
          <h3>5. Reinforcement Learning for Trading Decisions</h3>
          <p>
            One of the most advanced techniques in our system is <strong>Reinforcement Learning (RL)</strong>. In RL, an agent learns to make decisions by interacting with an environment and receiving feedback in the form of rewards or penalties. 
            For trading, the RL agent evaluates various actions (such as buying, selling, or holding assets) based on their expected future returns, and optimizes its strategy over time.
          </p>
          <BlockMath math={`Q(s, a) = r + \\gamma \\cdot \\max Q(s', a')`} />
          <p>
            Where:
            <ul>
              <li><strong>Q(s, a)</strong>: The Q-value, representing the expected future rewards for taking action <strong>a</strong> in state <strong>s</strong></li>
              <li><strong>r</strong>: The immediate reward from taking action <strong>a</strong></li>
              <li><strong>\\gamma</strong>: Discount factor, determining the importance of future rewards</li>
              <li><strong>s'</strong>: The new state after taking action <strong>a</strong></li>
            </ul>
          </p>
          <h4>Why Reinforcement Learning for Trading?</h4>
          <p>
            Unlike traditional algorithms, RL can continuously adapt to changing market conditions, making it ideal for the unpredictable nature of financial markets. 
            By training the agent over time, it can learn strategies that maximize profit under different market regimes, such as bullish, bearish, or sideways trends. 
            Moreover, by incorporating **action masking**, we ensure that the agent only takes valid actions based on market constraints, further improving performance.
          </p>
        </div>
      </div>

      {/* Conclusion Section */}
      <div className="conclusion-section">
        <h2>Conclusion</h2>
        <p>
          Through the use of advanced machine learning models such as GPTTrader, Gradient Boosting, LSTMs, XGBoost, and Reinforcement Learning, the Neural Trading Project aims to develop highly adaptive systems that are capable of understanding and predicting financial market movements. 
          By integrating a wide range of data sources and continuously refining our models, we are at the forefront of financial innovation, helping traders and investors navigate the complexities of the cryptocurrency and broader financial markets.
        </p>
      </div>
    </div>
  );
};

export default AboutProject;
